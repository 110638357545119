/* src/App.css or src/components/Footer.css */
footer {
  background-color: var(--metallic-silver);;
  color: var(--primary-color);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text */
}

footer h2 {
  font-size: 40px;
  color: var(--primary-color);
}

.rights-reserved {
  margin-top: 20px;
  font-size: 12px;
  color: var(--primary-color);
}

.footer-details {
  display: flex;
  flex-direction: column; /* Stack items in a column */
  align-items: center;
  margin-top: -20px;
}

.footer-details p {
  margin: 5px 0;
}