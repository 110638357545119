.dci-services-container {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 20px;
  /* background-color: #666; */
}

.dci-services-title {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 20px;
}

.dci-service-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  gap: 20px;
}

.dci-image-container {
  flex: 0 0 300px;
}

.dci-service-image {
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-radius: 8px;
}

.dci-content-container {
  width: 800px;
  flex: 1;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.dci-item-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.dci-item-description {
  color: #666;
  line-height: 1.5;
}

.subcategories-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.subcategory-item {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  transition: all 0.2s ease-in-out;
}

.subcategory-text {
  color: #495057;
  font-size: 0.95rem;
  line-height: 1.5;
}

.subcategory-text b {
  color: #212529;
  margin-right: 0.25rem;
}


/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .dci-services-container {
    padding: 15px;
  }

  .dci-services-title {
    font-size: 26px;
    text-align: center;
  }

  .dci-service-item {
    flex-direction: column;
    gap: 15px;
  }

  .dci-image-container {
    flex: auto;
    width: 100%;
  }

  .dci-service-image {
    height: 180px;
  }

  .dci-content-container {
    width: 100%;
    padding: 12px;
  }

  .dci-item-title {
    font-size: 16px;
  }

  .dci-item-description {
    font-size: 14px;
  }
}