/* src/pages/DieselCenter/DieselCenter.css */
.dc-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.dc-container {
  max-width: auto;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.dc-container h1 {
  font-size: 64px;
  font-weight: 1000;
  color: var(--primary-color);
}


/* ------- */

.dropdown-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.option-button {
  /* width: 100%;
  padding: 16px;
  background-color: var(--metallic-silver);
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 8px;
  transition: background-color 0.2s ease; */
  padding: 16px;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  font-weight: 500;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 100%;
  text-align: left;
  border-radius: 4px;
  background-color: var(--subtle-background-color);
  margin-bottom: 8px;
}

.option-button,
.dropdown-content {
  width: 100%; /* Ensure both have full width */
  box-sizing: border-box; /* Include padding and border in width */
}

.option-button:hover {
  background-color: var(--metallic-silver);
  /* transform: translateY(-2px); */
}

.dropdown-content {
  width: 100%;
  background-color: var(--background-color); /* Use background color */
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  animation: fadeIn 0.3s ease;
}

.dropdown-item {
  /* padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 4px;
  font-size: 14px;
  color: var(--text-color); */

  color: var(--text-color);
  padding: 12px 20px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  border-left: 3px solid transparent;
  transition: all 0.3s ease;
  cursor: pointer;
}

.dropdown-item:hover {
  /* background-color: var(--metallic-silver);
  padding-left: 20px; 
  color: var(--burgundy-red); */

  background-color: var(--subtle-background-color);
  color: var(--burgundy-red);
  border-left: 3px solid var(--burgundy-red);
  padding-left: 25px;
}

/* ------- end */

/* ----- search */

.search-bar {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-icon {
  position: absolute;
  left: 10px;
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
}

.search-bar input {
  width: 300px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.search-bar input:focus {
  outline: none;
  border-color: var(--burgundy-red);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
/* ----- search end */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Ensure the main dropdown items don't have a border */
.dc-dropdown-submenu:not(:last-child) {
  border-bottom: none;
}


/* Mobile Styles */
@media screen and (max-width: 768px) {
  .dc-container h1 {
    font-size: 36px;  /* Smaller heading for mobile */
  }

  .dropdown-container {
    max-width: 80%; /* Full width on mobile */
  }
}