/* Header container */
.header {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: fixed;
  background-color: var(--background-color);
  top: 6px;
  left: 0;
  right: 0;
  z-index: 1000;
}

.gradient {
  height: 6px; /* Adjust height as needed */
  background: linear-gradient(to right,
  #920002 0%,     /* bosch-red-30 */
  #920002 7.14%,
  #be0004 7.14%,  /* bosch-red-40 */
  #be0004 14.28%,
  #ed0007 14.28%, /* bosch-red-50 */
  #ed0007 21.42%,
  #9e2896 28.56%, /* bosch-purple-40 */
  #9e2896 35.7%,
  #791d73 35.7%,  /* bosch-purple-30 */
  #791d73 42.84%,
  #004975 42.84%, /* bosch-blue-30 */
  #004975 49.98%,
  #00629a 49.98%, /* bosch-blue-40 */
  #00629a 57.12%,
  #007bc0 57.12%, /* bosch-blue-50 */
  #007bc0 64.26%,
  #56b0ff 64.26%, /* bosch-blue-70 */
  #56b0ff 71.4%,
  #9dc9ff 71.4%,  /* bosch-blue-80 */
  #9dc9ff 78.54%,
  #00512a 78.54%, /* bosch-green-30 */
  #00512a 85.68%,
  #006c3a 85.68%, /* bosch-green-40 */
  #006c3a 92.82%,
  #00884a 92.82%, /* bosch-green-50 */
  #00884a 100%
);
  position: fixed; /* Keep it fixed at the top */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  z-index: 2000;
}

/* Logo styling */
.logo {
  /* border-radius: 50%; */
  height: 38px;
  width: 40px;
  cursor: pointer;
}

.company-name {
  margin-left: 10px;
  margin-right: 20px;
  font-size: 35px;
  font-weight: bold;
  color: var(--primary-color);
  cursor: pointer;
}

/* Hamburger icon styling */
.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--burgundy-red);
  z-index: 1000;
  margin-left: auto;
}

.header-nav-links {
  display: flex;
  gap: 0px;
  align-items: center;
 }
 
 .header-dropdown {
  position: relative;
  display: inline-block;
 }
 
 .header-dropdown > a {
  padding: 10px 15px;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  font-weight: 500;
  /* text-transform: uppercase; */
  /* letter-spacing: 1px; */
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease;
 }
 
 /* Underline animation for main links */
 .header-dropdown > a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: var(--burgundy-red);
  transition: all 0.3s ease;
  transform: translateX(-50%);
 }
 
 .header-dropdown > a:hover::after {
  width: 80%;
 }
 
 .header-dropdown > a:hover {
  color: var(--burgundy-red);
 }
 
 .header-dropdown-content {
  display: none;
  position: absolute;
  min-width: 220px;
  background-color: var(--background-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 1000;
  margin-top: 10px;
  border: 1px solid var(--subtle-background-color);
  backdrop-filter: blur(10px);
 }
 
 /* Arrow indicator for dropdown */
 /* .header-dropdown-content::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 20px;
  width: 10px;
  height: 10px;
  background-color: var(--background-color);
  transform: rotate(45deg);
  border-left: 1px solid var(--subtle-background-color);
  border-top: 1px solid var(--subtle-background-color);
 } */
 
 .header-dropdown:hover .header-dropdown-content,
 .header-dropdown-content.show {
  display: block;
  animation: fadeIn 0.3s ease;
 }
 
 .header-dropdown-content a {
  color: var(--text-color);
  padding: 12px 20px;
  text-decoration: none;
  display: block;
  font-size: 14px;
  border-left: 3px solid transparent;
  transition: all 0.3s ease;
 }
 
 .header-dropdown-content a:hover {
  background-color: var(--subtle-background-color);
  color: var(--burgundy-red);
  border-left: 3px solid var(--burgundy-red);
  padding-left: 25px;
 }
 
 @keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
 }

/* Styles for smaller screens */
@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .company-name {
    font-size: 35px;
  }

  /* .nav-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    right: -100%;
    width: 50%;
    height: 100%;
    background-color: var(--background-color);
    padding-left: 20px;
    padding-top: 20px;
    transition: right 0.3s ease;
    z-index: 999;
  } */

  /* .nav-links.open {
    right: 0;
  } */

  /* .nav-links a {
    color: var(--text-color);
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    margin: 10px 0;
    transition: color 0.3s ease;
  }

  .nav-links a:hover {
    color: var(--deep-red);
  } */


  .header-nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--background-color);
    padding: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
 
  .header-nav-links.open {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
 
  .header-dropdown {
    width: 100%;
  }
 
  .header-dropdown-content {
    position: static;
    width: 100%;
    margin-left: 1rem;
    box-shadow: none;
    border-left: 2px solid var(--burgundy-red);
  }
 
  .header-dropdown-content::before {
    display: none;
  }

  .header-dropdown-content a {
    padding: 0.75rem 1rem;
  }

  .hamburger {
    cursor: pointer;
  }
}