.card-d-details {
  margin-top: 40px;
  min-height: 130vh;
  background-color: #f8f9fa;
}

.card-d-banner {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 3rem;
  overflow: hidden;
}

.card-d-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
}

.card-d-banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.4));
}

.card-d-details-title {
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 0;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}

.card-d-details-container {
  padding: 0 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.card-d-details-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-bottom: 3rem;
}

.card-d-details-section {
  padding: 2rem;
}

.card-d-section-title {
  color: var(--burgundy-red);
  margin: 0 0 1.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f0f0f0;
}

.card-d-details-grid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card-d-detail-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card-d-detail-label {
  color: #666;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.card-d-detail-value {
  color: #333;
  font-size: 1.1rem;
}

.card-d-description .card-d-detail-value {
  line-height: 1.6;
}

.card-d-product-link-container {
  margin-top: 1rem;
  padding-top: 1.5rem;
  border-top: 2px solid #f0f0f0;
}

.card-d-product-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--burgundy-red);
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.card-d-product-link:hover {
  transform: translateY(-1px);
}

.card-d-link-arrow {
  transition: transform 0.3s ease;
}

.card-d-product-link:hover .card-d-link-arrow {
  transform: translateX(4px);
}

@media (max-width: 768px) {
  .card-d-banner {
    height: 250px;
  }

  .card-d-details-title {
    font-size: 2rem;
  }

  .card-d-details-container {
    padding: 0 1rem;
  }

  .card-d-details-section {
    padding: 1.5rem;
  }
}