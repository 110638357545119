.accessories-details {
  padding: 2rem;
  max-width: 900px;
  margin: 40px auto;
}

.accessories-details-title {
  color: #333;
  font-size: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.accessories-details-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.accessories-details-group {
  background-color: #f8f8f8;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.accessories-details-group h3 {
  color: #444;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.accessories-details-group p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.accessories-details-product-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.accessories-details-product-link {
  display: inline-block;
  width: fit-content;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #0066cc;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.accessories-details-product-link:hover {
  background-color: #0052a3;
}