.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}

.home-container {
  max-width: auto;
  /* margin-top: 60px; */
  margin-left: 20px;
  padding: 20px;
  /* text-align: center; */
  color: var(--text-color);
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.company-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px
}

.home-container h1 {
  font-size: 64px;
  font-weight: 1000;
  color: var(--primary-color);
}

.company-description {
  max-width: 700px;
  line-height: 30px;
  font-size: 20.8px;
  text-align: left;
}

.home-card-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  background-color: var(--subtle-background-color);
  border-radius: 8px;
  /* padding: 20px 100px; */
}

.home-card {
  border-radius: 8px;
  padding: 20px 20px 0px 20px;
  position: relative;
  width: 200px;
  overflow: hidden;
  transition: width 0.3s ease, opacity 0.5s ease;
}

.home-card-image {
  border-radius: 8px;
  width: 200px;
  height: 280px;
  object-fit: contain;
  /* Keep the main image size constant */
}

.home-card-container:hover .home-card {
  /* padding: 0px; */
  opacity: 0;
  /* Fade out all cards when container is hovered */
}

.home-card:hover {
  width: 800%;
}

.home-card-container:hover .home-card:hover {
  padding: 20px 20px 0px 20px;
  opacity: 1;
  z-index: 1;
}

.additional-images {
  display: none;
  position: absolute;
  top: 0;
  left: 220px;
  margin: 10px;
  width: calc(100% - 200px);
}

.additional-images .image-wrapper {
  display: inline-block;
  position: relative;
  text-align: center;
  margin: 10px;
}

.additional-images img {
  border-radius: 8px;
  margin: 0;
  margin-top: 30px;
  width: 160px;
  height: 160px;
  /* height: auto; */
  object-fit: contain;
}

.additional-images .image-label {
  display: block;
  margin-top: 5px;
  color: var(--text-color);
  font-size: 14px;
}

.home-card:hover .additional-images {
  display: block;
}

/* -- Diesel Center */

.services-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 0 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.service-card {
  position: relative;
  flex: 1;
  max-width: 600px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background: var(--background-color);
}

.service-card:hover {
  transform: translateY(-5px);
}

.main-image-container {
  position: relative;
  width: 300px;
  height: 400px;
}

.main-image {
  /* width: 100%; */
  width: 300px;
  height: 400px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.service-card:hover .main-image {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(51, 51, 51, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.service-card:hover .overlay {
  background: rgba(51, 51, 51, 0.7);
}

.content {
  text-align: center;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.service-card:hover .content {
  transform: translateY(-30px);
}

.overlay h2 {
  color: var(--background-color);
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* .overlay p {
  color: #f0f0f0;
  font-size: 1.1rem;
  max-width: 80%;
  margin: 0 auto;
} */

.hover-images {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 0 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.service-card:hover .hover-images {
  opacity: 1;
  transform: translateY(0);
}

.hover-image-container {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid white;
  transition: transform 0.3s ease;
}

.hover-image-container:hover {
  transform: scale(1.1);
}

.hover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* -- Diesel Center end */

.choose-us-box {
  display: flex;
  justify-content: center;
  /* Distributes space evenly between cards */
  flex-wrap: wrap;
  margin: 20px 0;
  /* Adds vertical spacing */
}

.choose-us-card {
  /* background-color: #f9f9f9; */
  /* background-color: var(--subtle-background-color); */
  /* border: 1px solid var(--metallic-silver); */
  border-radius: 8px;
  /* Rounded corners */
  padding: 20px;
  /* Inner spacing */
  text-align: center;
  /* Center-aligns text */
  width: 18%;
  /* Sets a width for each card */
  margin: 10px;
  /* Adds spacing between cards */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  */
  transition: transform 0.2s;
  /* Smooth transition for hover effect */
}

.choose-us-icon {
  width: 50px;
  /* Sets a fixed width for icons */
  height: auto;
  /* Maintains aspect ratio */
  margin-bottom: 0px;
  /* Adds spacing below the icon */
}

/* Add media query for mobile responsiveness */
@media (max-width: 768px) {
  .home-container {
    flex-direction: column;
    /* Stack items vertically on mobile */
    margin-left: 0;
    /* Remove left margin */
    padding: 10px;
    /* Adjust padding for smaller screens */
  }

  .company-info {
    margin: 10px;
    /* Reduce margin for mobile */
  }

  .home-container h1 {
    font-size: 45px;
    /* Reduce font size for mobile */
    text-align: center;
  }

  .home-card-container {
    /* ... existing code ... */
    flex-wrap: wrap;
    /* padding: 0px; */
    /* Allow cards to wrap on smaller screens */
  }

  .home-card-label {
    /* ... existing code ... */
    font-size: 16px;
    /* Adjust font size for better readability on mobile */
  }

  .home-card {
    width: 100%;
    max-width: 250px;
    transition: none;
  }

  .home-card:hover .additional-images {
    display: none;
  }

  .home-card-container:hover .home-card {
    opacity: 1;
  }

  .home-card-image {
    width: 100%;
    height: auto;
  }

  .additional-images {
    display: none;
  }

  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .service-card {
    width: 100%;
    max-width: 500px;
  }

  .main-image-container {
    height: 300px;
  }

  .company-description {
    max-width: 100%;
    /* Allow description to use full width */
    font-size: 16px;
    /* Adjust font size for readability */
  }

  .choose-us-box {
    flex-direction: column;
    /* Stack cards vertically on mobile */
    align-items: center;
    /* Center-align cards */
  }

  .choose-us-card {
    width: 80%;
    /* Make cards take up more width on mobile */
    margin: 10px 0;
    /* Adjust margin for vertical spacing */
  }
}

@media (max-width: 480px) {
  .hover-image-container {
    width: 60px;
    height: 60px;
  }

  .overlay h2 {
    font-size: 1.5rem;
  }

  .overlay p {
    font-size: 1rem;
  }
}